<template>
  <swiper class="swiper" ref="mySwiperRef" :options="swiperOption">
    <swiper-slide
      v-for="(product, index) in products"
      :key="`product-${index}}`"
    >
      <div class="product" v-b-modal="`product-modal-${index}`">
        <h4 class="product-title">{{ product.title }}</h4>
        <picture>
          <img :src="require(`@/assets/products/${product.img}`)" :alt="product.title" />
        </picture>
      </div>

      <product-modal :id="`product-modal-${index}`" :product="product" />
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
    <div class="swiper-button-prev" slot="button-prev" @click="prev()"></div>
    <div class="swiper-button-next" slot="button-next" @click="next()"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "../assets/css/swiper.css";
import products from "../assets/content/products.json";
import ProductModal from "./ProductModal.vue";

export default {
  name: "ProductSlider",
  components: {
    Swiper,
    SwiperSlide,
    ProductModal,
  },
  data() {
    return {
      products: products,
      swiperOption: {
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 2500,
          disableOnInteraction: true,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {
          1200: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          500: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    };
  },
  methods: {
    prev() {
      this.$refs.mySwiperRef.$swiper.slidePrev();
    },
    next() {
      this.$refs.mySwiperRef.$swiper.slideNext();
    },
  },
};
</script>

<style scoped>
.product-title {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.3rem;
  font-weight: var(--FONT_LIGHT);
}

.product picture {
  display: flex;
  justify-content: center;
}

.product img {
  min-width: 270px;
  max-width: 50vh;
}
</style>