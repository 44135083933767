<template>
  <nav class="main-navbar" :class="{ active: isActive }">
    <a href="#meu-armazem" class="main-logo">
      <img
        alt="logo pequena do armazém supermercado"
        src="../assets/logo-bigger.png"
      />
    </a>
    <div class="menu">
      <div class="menu-line" @click="isActive = !isActive">
        <b-icon-list v-if="!isActive" font-scale="2" />
        <b-icon-x v-if="isActive" font-scale="2" />
      </div>
    </div>

    <div class="nav-list" :class="{ active: isActive }">
      <li class="list-item" :class="{ active: isItemActive('meu-armazem') }">
        <a href="#meu-armazem">Meu Armazém</a>
      </li>
      <li class="list-item" :class="{ active: isItemActive('contatos') }">
        <a href="#contatos">Contato</a>
      </li>
      <li class="list-item" :class="{ active: isItemActive('localizacao') }">
        <a href="#localizacao">Localização</a>
      </li>
      <li
        class="list-item optional"
        :class="{ active: isItemActive('nossas-marcas') }"
      >
        <a href="#nossas-marcas">Nossas Marcas</a>
      </li>
      <li
        class="list-item optional"
        :class="{ active: isItemActive('nossos-produtos') }"
      >
        <a href="#nossos-produtos">Nossos produtos</a>
      </li>
      <li class="list-item about" :class="{ active: isAboutActive }">
        <a href="#nossas-marcas">Sobre</a>
      </li>
    </div>
  </nav>
</template>

<script>
import { BIconList, BIconX } from "bootstrap-vue";

export default {
  name: "NavBar",
  props: { activeSection: { type: String, default: "app-store" } },
  data() {
    return { isActive: false };
  },
  methods: {
    isItemActive(item) {
      return this.activeSection == item;
    },
  },
  computed: {
    isAboutActive: function () {
      return (
        this.activeSection == "nossas-marcas" ||
        this.activeSection == "nossos-produtos"
      );
    },
  },
  components: {
    BIconList,
    BIconX,
  },
};
</script>

<style scoped>
.main-navbar {
  background-color: transparent;

  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;

  font-weight: var(--FONT_BOLD);

  padding: var(--BLOCK_SPACE_MIN) var(--BLOCK_SPACE);

  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 2 !important;
}

.main-logo {
  margin-right: var(--BLOCK_SPACE_3x);
}

.main-logo img {
  height: 50px;
}

.nav-list {
  list-style-type: none;

  border-bottom: 2px solid var(--WHITE);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-item {
  padding: 0 var(--BLOCK_SPACE);
  position: relative;
}

.list-item a {
  position: relative;
  z-index: 101;
}

.main-navbar a {
  color: var(--WHITE);
  text-decoration: none;
  text-transform: uppercase;

  font-size: 1.5rem;
  text-align: justify;
}

.menu {
  display: none;
}

.about {
  display: none;
}

@media (min-width: 600px) and (max-width: 950px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .main-navbar a {
    font-size: 2.2vw;
  }

  .list-item {
    padding: 0 0.7vw;
    padding-bottom: 5px;
    padding-top: 10px;
  }
}
@media (max-width: 1100px) {
  .main-navbar a {
    font-size: 2.5vw;
  }
}

@media (min-width: 950px) and (max-width: 1281px) {
  /* big landscape tablets, laptops, and desktops */

  .optional {
    display: none;
  }

  .main-navbar a {
    color: var(--WHITE);
    text-decoration: none;
    text-transform: uppercase;

    font-size: 2vw;
  }

  .list-item {
    padding: 0 1vw;
    padding-bottom: 5px;
    padding-top: 10px;
  }

  .about {
    display: inline-block;
  }
}

@media (min-width: 1281px) {
  .main-navbar {
    justify-content: space-between;
  }
  .main-navbar a {
    font-size: 1.4rem;
  }

  .list-item {
    padding: 0 var(--BLOCK_SPACE_MIN);
    padding-bottom: 5px;
    padding-top: 10px;
  }
}

@media (max-width: 950px) {
  .optional {
    display: none;
  }

  .about {
    display: inline-block;
  }
}
@media (max-width: 700px) {
  .main-navbar.active {
    background-color: var(--RED_LIGHT);
  }
  .menu {
    display: block;
    position: absolute;
    right: 15px;
    top: 20px;
  }

  .nav-list {
    display: none;
    width: 100%;
    text-align: center;
    padding-top: 20px;
    margin-top: 20px;
  }

  .list-item {
    display: block;
    border-top: 1px solid var(--WHITE);
    padding: var(--BLOCK_SPACE_MIN) 0;
    background-color: var(--RED_LIGHT);
  }

  .main-navbar {
    flex-direction: column;
    align-content: center;
    padding-top: var(--BLOCK_SPACE_MIN);
  }
  .main-logo {
    justify-content: center;
    margin-right: 0;
  }

  .nav-list.active {
    display: block;
  }

  .about {
    display: none;
  }
}

@media (min-width: 700px) {
  .list-item:after {
    background: linear-gradient(
      180deg,
      rgba(208, 31, 52, 1) 65%,
      rgba(2, 0, 36, 0) 100%
    );
    z-index: 100;
    height: 175%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    content: "";
    visibility: hidden;
    opacity: 0;

    transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  .list-item:hover:after,
  .list-item.active:after {
    content: "";
    visibility: visible;
    opacity: 1;
    transition: visibility 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
}
</style>