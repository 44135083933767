<template>
  <section id="contatos" class="contacts-container p-2">
    <div class="contacts">
      <div class="contacts-info">
        <div class="contact facebook">
          <img
            src="../assets/face-logo.png"
            alt="logo do facebook"
            class="contact-logo"
          />
          <a href="https://pt-br.facebook.com/armazemsupermercado/"
            >/armazemsupermercado</a
          >
        </div>
        <div class="contact instagram">
          <img
            src="../assets/insta-logo.png"
            alt="logo do instagram"
            class="contact-logo"
          />
          <a href="https://www.instagram.com/armazemsupermercado/"
            >@armazemsupermercado</a
          >
        </div>
        <div class="contact whatsapp">
          <img
            src="../assets/whats-logo.png"
            alt="logo do whatsapp"
            class="contact-logo"
          />
          <a href="https://wa.me/5562991317363">62&nbsp;99131-7363</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contacts",
};
</script>

<style>
.contacts-container {
  background-color: var(--RED_DARKEN);
  height: 100vh;
  background-image: url("../assets/delivery.png");
  background-repeat: no-repeat;
  background-size: initial;
  background-position-x: 30px;
}
.contacts-info {
  width: 50%;
  height: 100%;
  display: grid;
  align-items: center;
  padding: var(--BLOCK_SPACE_2x);
}

.contacts {
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: flex-start;
}

.contact {
  display: grid;
  justify-content: flex-start;
  grid-template-columns: 50px 1fr;
  align-items: center;

  grid-gap: 3%;
}

.contact a {
  font-size: 2.4rem;
  font-weight: var(--FONT_BOLD);
  text-align: center;

  border: solid 2px var(--WHITE);
  padding: 3px 10px;
  border-radius: var(--RADIUS_11);
  background-color: var(--RED_DARKEN);
}

.contact-logo {
  width: 40px;
  height: 40px;
}

@media (max-width: 700px) {
  .contacts-container {
    height: 80vh;
  }

  .contacts {
    justify-content: center;
  }
  .contacts-info {
    padding: 0 10px;
    width: 100%;
    height: 70%;
    place-self: center;
  }

  .contact-logo {
    width: 30px;
    height: 30px;
  }

  .contact {
    grid-template-columns: min(30px, 40px) 1fr;
    grid-auto-rows: 1fr;

    align-content: center;
    justify-items: center;
  }
  .contact a {
    font-size: 5vw;
    width: 100%;
  }
}
</style>