<template>
  <div id="app">
    <nav-bar :activeSection="activeElement" />
    <app-store
      id="meu-armazem"
      v-waypoint="{
        active: true,
        callback: onScreen,
        options: { threshold: [0.5] },
      }"
    />
    <contacts
      id="contatos"
      v-waypoint="{
        active: true,
        callback: onScreen,
        options: { threshold: [0.5] },
      }"
    />
    <location
      id="localizacao"
      v-waypoint="{
        active: true,
        callback: onScreen,
        options: { threshold: [0.5] },
      }"
    />
    <about
      id="nossas-marcas"
      v-waypoint="{
        active: true,
        callback: onScreen,
        options: { threshold: [0.5] },
      }"
    />
    <products
      id="nossos-produtos"
      v-waypoint="{
        active: true,
        callback: onScreen,
        options: { threshold: [0.5] },
      }"
    />
    <footer-page />
  </div>
</template>

<script>
import AppStore from "./pages/AppStore";
import NavBar from "./components/NavBar";
import Contacts from "./pages/Contacts";
import Location from "./pages/Location";
import Products from "./pages/Products";
import About from "./pages/About";
import FooterPage from "./components/Footer";

export default {
  name: "App",
  data: () => ({
    intersectionOptions: {
      root: null,
      rootMargin: "0px 0px 0px 0px",
      threshold: [0, 1],
    },
    activeElement: "meu-armazem",
  }),
  methods: {
    onScreen({ el, going }) {
      if (this.$waypointMap.GOING_IN === going) {
        this.activeElement = el.getAttribute("id");
      }
    },
  },
  components: {
    NavBar,
    AppStore,
    Contacts,
    Location,
    Products,
    FooterPage,
    About,
  },
};
</script>

<style>
#app {
  background-color: var(--RED_LIGHT);
}
</style>
