<template>
  <b-modal
    :id="id"
    centered
    :content-class="'product-modal'"
    :hide-footer="true"
    :size="'lg'"
  >
    <h3 class="text-center mb-3 product-title">{{ product.title }}</h3>
    <div class="product-info">
      <div class="product-description mb-4">
        {{ product.description }}
      </div>
      <picture>
        <img
          :src="require(`@/assets/products/${product.img}`)"
          :alt="product.description"
          class="modal-product-image"
        />
      </picture>
    </div>
  </b-modal>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";

export default {
  name: "ProductModal",
  props: { id: String, product: Object },
};
</script>

<style>
.product-modal {
  background-color: var(--RED_DARKEN) !important;
  color: var(--WHITE);
  border: 0;
  border-radius: var(--RADIUS_9);
}

.product-modal .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.product-modal .modal-body {
  padding: var(--BLOCK_SPACE) var(--BLOCK_SPACE_2x);
  padding-top: 0;
}

.product-description {
  text-align: justify;
  font-weight: var(--FONT_LIGHT);
  font-size: 1.3rem;
}

.product-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-title {
  text-transform: uppercase;
  font-weight: var(--FONT_MEDIUM);
}

picture .modal-product-image {
  max-width: 100%;
  width: 24vw;
  border-radius: var(--RADIUS_9);
}

@media (max-width: 700px) {
  picture .modal-product-image {
    width: 50vw;
  }
}
</style>