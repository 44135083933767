<template>
  <section id="localizacao" class="location-container">
    <div class="location">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15392.549562898226!2d-49.5968084!3d-15.3147669!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf4754653563065d8!2sArmaz%C3%A9m%20Supermercado!5e0!3m2!1spt-BR!2sbr!4v1645393963368!5m2!1spt-BR!2sbr"
        class="map"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
      ></iframe>
    </div>
  </section>
</template>

<script>
export default {
  name: "Location",
};
</script>

<style>
.location-container {
  background-color: var(--RED_LIGHT);
  height: 100vh;
  background-image: url("../assets/location-background.png");
  background-repeat: no-repeat;
  background-size: initial;
  background-position-x: 30px;
}
.location {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  padding: var(--BLOCK_SPACE_MIN) var(--BLOCK_SPACE_2x);
  padding-bottom: var(--BLOCK_SPACE_2x);
}
.map {
  width: 70%;
  height: 80%;
  border-radius: var(--RADIUS_9);
}

@media (max-width: 700px) {
  .location {
    display: flex;
    align-content: center;
    justify-content: center;
    height: 100%;
    padding: var(--BLOCK_SPACE) var(--BLOCK_SPACE);
  }

  .map {
    width: 100%;
    height: 80%;
    border-radius: var(--RADIUS_9);
  }
}
</style>