<template>
  <div class="about-container d-flex">
    <div class="about">
      <div class="store-description mb-5">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam autem,
        distinctio quas sint nam tempora. Soluta, labore quasi
      </div>

      <div class="brands-container">
        <h2 class="brand-title mb-4 px-2">NOSSAS MARCAS</h2>

        <div class="brands">
          <div class="brand mb-3">
            <img src="../assets/beef-logo.png" alt="logo do açougue do armazém supermercado" class="logo" />
            <div class="brand-description">
              Inovação,Transparência, Humildade, Respeito, Trabalho, Ética,
              Responsabilidade, Excelência, Disciplina e Eficiência.
            </div>
          </div>
          <div class="brand">
            <img
              src="../assets/hortifruti-logo.png"
              alt="beef logo"
              class="logo"
            />
            <div class="brand-description">
              Inovação,Transparência, Humildade, Respeito, Trabalho, Ética,
              Responsabilidade, Excelência, Disciplina e Eficiência.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style scoped>
.about-container {
  background-color: var(--RED_DARKEN);
  min-height: 100vh;
  padding: var(--BLOCK_SPACE_2x) var(--BLOCK_SPACE_2x);
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;

  font-weight: var(--FONT_LIGHT);
}

.about {
  height: 100%;
  max-height: 100%;
}

.store-description {
  font-size: 2rem;
  margin-right: var(--BLOCK_SPACE_4x);
}

.brands {
  margin-left: var(--BLOCK_SPACE_MIN)
}

.brand .logo {
  margin: 10px var(--BLOCK_SPACE);
  margin-right: var(--BLOCK_SPACE_2x);
  width: 15vw;
  height: 15vw;
  max-width: 150px;
  max-height: 150px;
  align-self: center;
}

.brand {
  display: flex;
  align-content: center;
}

.brand-description {
  align-self: center;
  font-size: 1.7rem;
  width: 75%;
  max-width: 1100px;
}

.brands {
  border-left: 2px solid var(--WHITE);
}

@media (max-width: 700px) {
  .brand-title {
    text-align: center;
  }
  .about-container {
    padding: var(--BLOCK_SPACE_2x) var(--BLOCK_SPACE);
  }
  .store-description {
    font-size: 1.3em;
    margin-right: var(--BLOCK_SPACE_MIN);
  }

  .brand {
    flex-direction: column;
    justify-items: center;
  }

  .brand-description {
    font-size: 1.3rem;
    text-align: center;
  }

  .brand .logo {
    width: 20vw;
    height: 20vw;
  }
}
</style>