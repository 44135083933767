var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('nav-bar',{attrs:{"activeSection":_vm.activeElement}}),_c('app-store',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({
      active: true,
      callback: _vm.onScreen,
      options: { threshold: [0.5] },
    }),expression:"{\n      active: true,\n      callback: onScreen,\n      options: { threshold: [0.5] },\n    }"}],attrs:{"id":"meu-armazem"}}),_c('contacts',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({
      active: true,
      callback: _vm.onScreen,
      options: { threshold: [0.5] },
    }),expression:"{\n      active: true,\n      callback: onScreen,\n      options: { threshold: [0.5] },\n    }"}],attrs:{"id":"contatos"}}),_c('location',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({
      active: true,
      callback: _vm.onScreen,
      options: { threshold: [0.5] },
    }),expression:"{\n      active: true,\n      callback: onScreen,\n      options: { threshold: [0.5] },\n    }"}],attrs:{"id":"localizacao"}}),_c('about',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({
      active: true,
      callback: _vm.onScreen,
      options: { threshold: [0.5] },
    }),expression:"{\n      active: true,\n      callback: onScreen,\n      options: { threshold: [0.5] },\n    }"}],attrs:{"id":"nossas-marcas"}}),_c('products',{directives:[{name:"waypoint",rawName:"v-waypoint",value:({
      active: true,
      callback: _vm.onScreen,
      options: { threshold: [0.5] },
    }),expression:"{\n      active: true,\n      callback: onScreen,\n      options: { threshold: [0.5] },\n    }"}],attrs:{"id":"nossos-produtos"}}),_c('footer-page')],1)}
var staticRenderFns = []

export { render, staticRenderFns }