import Vue from 'vue'
import App from './App.vue'

import VueWaypoint from 'vue-waypoint'

import { Swiper as SwiperClass, Pagination, Navigation, Autoplay } from "swiper/swiper.esm"
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter"


import BootstrapVue from 'bootstrap-vue'
import { IconsPlugin } from 'bootstrap-vue'
import './assets/css/app.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueWaypoint)

SwiperClass.use([Pagination, Navigation, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))

const { directive } = getAwesomeSwiper(SwiperClass)
Vue.directive("swiper", directive)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
