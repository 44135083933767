<template>
  <section class="store-app-container" id="#meu-armazem">
    <div
      class="
        store-app
        d-flex d-md-flex
        flex-sm-row
        justify-content-center
        align-items-spe
      "
    >
      <picture class="d-none d-xl-flex app-demo align-self-center">
        <img
          src="../assets/smartphone-with-app.png"
          alt="smartphone com o aplicativo armazém instalado"
        />
      </picture>

      <div
        class="
          logo-and-download-buttons
          d-flex
          flex-column flex-md-row
          justify-content-center
        "
      >
        <div class="logo align-self-sm-start p-4">
          <picture>
            <img
              src="../assets/small-logo.png"
              alt="logo pequena do supermercado armazém"
            />
          </picture>
        </div>

        <div
          class="
            download-buttons
            align-self-md-center align-self-sm-center
            justify-self-center
            d-flex
            justify-content-center
          "
          @click="detectDevice"
        >
          <picture>
            <img
              src="../assets/download-in-stores.png"
              alt="link para o download do aplicativo"
            />
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppStore",
  data() {
    return {
      userAgent: window.navigator.userAgent,
    };
  },
  methods: {
    isIphone() {
      return this.userAgent.match(/iPhone/);
    },
    isIpad() {
      return this.userAgent.match(/iPad/);
    },
    isAndroid() {
      return this.userAgent.match(/Android/);
    },

    detectDevice() {
      const yourApp = "armazem-supermercado";
      // const yourApp = 'pinterest'
      if (this.isIphone() || this.isAndroid() || this.isIpad()) {
        window.location.replace(`${yourApp}://`);
      }

      setTimeout(() => {
        this.redirectToStore();
      }, 500);
    },
    redirectToStore() {
      const iphoneStoreUrl =
        "https://apps.apple.com/ma/app/armaz%C3%A9m-supermercado/id1568161346";
      if (this.isIphone() || this.isIpad()) {
        window.location.replace(iphoneStoreUrl);
        return;
      }

      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.VRSoft.G353&hl=pt_BR&gl=US&showAllReviews=true"
      );
    },
  },
};
</script>

<style>
.store-app-container {
  height: calc(100vh + 60px);
  padding: var(--BLOCK_SPACE_MIN) var(--BLOCK_SPACE);
}

.store-app {
  background-image: url("../assets/backgroung-download-app.png");
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.app-demo {
  width: 60%;
  height: 50vw;

  max-height: 80%;
}

.app-demo img {
  width: 80%;
  height: auto;
  max-width: 900px;

  animation: fade-in ease-in 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.download-buttons {
  justify-self: center;
  max-width: 100%;

  cursor: pointer;
}

.download-buttons img {
  width: 100%;
  max-width: 100%;
}

.logo {
  width: 80%;
  max-width: 100%;
}

.logo img {
  width: 100%;
}

@media (max-width: 700px) {
  .store-app-container {
    height: calc(100vh + 60px);
  }

  .store-app {
    align-items: flex-start;
  }
  .logo {
    width: 100%;
    margin-bottom: var(--BLOCK_SPACE_2x);
  }

  .download-buttons img {
    width: 50vw;
  }

  .logo-and-download-buttons {
    justify-content: space-between;
  }
}
</style>