<template>
  <section id="nossos-produtos" class="products-container">
    <div class="products">
      <h2 class="products-title">Nossos Produtos</h2>

      <div class="products-list">
        <product-slider />
      </div>
    </div>
  </section>
</template>

<script>
import ProductSlider from "../components/ProductSlider.vue";

export default {
  name: "Products",
  components: {
    ProductSlider,
  },
};
</script>

<style>
.products-container {
  height: calc(80vh + 60px);
  padding: var(--BLOCK_SPACE_2x) 0;
  background-color: var(--RED_LIGHT);
}
.products {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.products-title {
  text-align: center;
  text-transform: uppercase;
}

.close,
.close:hover {
  color: var(--WHITE);
}

.products-list {
  padding: var(--BLOCK_SPACE_4x) var(--BLOCK_SPACE_2x);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

@media (max-width: 700px) {
  .products-list {
    padding: var(--BLOCK_SPACE_4x) var(--BLOCK_SPACE_MIN);
  }
}
</style>