<template>
  <section class="footer-container">
    <div class="side side-left"></div>
    <div class="logo-container">
      <img
        alt="logo grande do supermercado armazém"
        src="../assets/logo-bigger.png"
        class="footer-main-logo"
      />
      <img alt="logo do açougue beef" src="../assets/beef-logo.png" class="footer-logo" />
      <img
        alt="Hortifruti logo"
        src="../assets/hortifruti-logo.png"
        class="footer-logo"
      />
    </div>
    <div class="side side-right"></div>
  </section>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style>
.footer-container {
  height: 15vh;
  background-color: var(--RED_LIGHT);

  display: grid;
  grid-template-columns: 1.4fr 1fr 1.4fr;
}

.side {
  height: 50%;
  border-top: 3px solid var(--WHITE);
  align-self: flex-end;
}

.side-left {
  margin-right: var(--BLOCK_SPACE);
}

.side-right {
  margin-left: var(--BLOCK_SPACE);
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: space-around;
  align-content: center;
  width: 100%;
  max-width: 350px;

  justify-self: center;
}

.logo-container .footer-logo {
  height: 7vh;
}

.footer-main-logo{
  height: 4vh;
  align-self: center;
}

@media (max-width: 700px) {
  .side-left {
    margin-right: var(--BLOCK_SPACE_MIN);
  }

  .side-right {
    margin-left: var(--BLOCK_SPACE_MIN);
  }

  .logo-container img {
    /* height: 5vh; */
  }

  .footer-container {
    grid-template-columns: 0.8fr 1.2fr 0.8fr;
  }
}

@media (max-width: 550px) {
  .side {
    display: none;
  }
  .footer-container {
    grid-template-columns: 1fr;
  }
}


</style>